import { Toolbar } from '@mui/material';
import React from 'react'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Toolbar>
          Dana Finster
        </Toolbar>
      </header>
    </div>
  );
}

export default App;
